<template>
    <div class="pageContainer flexCenter" style="width:100%;">
        <div class="flexCloumnCenter" style="width:100%;">
            <img src="../../public/img/web_default.png" alt="">
            <div class="">
                敬请期待
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    }
}
</script>
